// import WormholeConnect from '@wormhole-foundation/wormhole-connect';
import NESG from "./Assets/NESG.png"
import WormholeConnect, {
  WormholeConnectConfig,
} from "@wormhole-foundation/wormhole-connect";
import red from "@mui/material/colors/red";
import lightblue from "@mui/material/colors/lightBlue";
import grey from "@mui/material/colors/grey";
import green from "@mui/material/colors/green";
import orange from "@mui/material/colors/orange";
import ResponsiveAppBar from "./Components/Navbar";

const config: WormholeConnectConfig = {
  env: "mainnet",
  networks: ["ethereum", "polygon", "bsc"],
  tokens: ["ETH", "WETH", "MATIC", "WMATIC", "BNB", "WBNB", "NESG", "USDT", "BUSD"],
  // customTheme: {
  //   mode: 'dark',
  //   primary: grey,
  //   secondary: grey,
  //   divider: 'rgba(255, 255, 255, 0.2)',
  //   background: {
  //     default: "#333333",
  //   },
  //   text: {
  //     primary: "#ffffff",
  //     secondary: grey[500],
  //   },
  //   error: red,
  //   info: lightblue,
  //   success: green,
  //   warning: orange,
  //   button: {
  //     primary: 'rgba(255, 255, 255, 0.2)',
  //     primaryText: "#ffffff",
  //     disabled: 'rgba(255, 255, 255, 0.1)',
  //     disabledText: 'rgba(255, 255, 255, 0.4)',
  //     action: orange[300],
  //     actionText: "#000000",
  //     hover: 'rgba(255, 255, 255, 0.7)',
  //   },
  //   options: {
  //     hover: "#474747",
  //     select: "#5b5b5b",
  //   },
  //   card: {
  //     background: "#333333",
  //     secondary: "#474747",
  //     elevation: "none",
  //   },
  //   popover: {
  //     background: "#333333",
  //     secondary: 'rgba(255, 255, 255, 0.5)',
  //     elevation: "none",
  //   },
  //   modal: {
  //     background: "#333333",
  //   },
  //   font: {
  //     primary: 'Basel, sans-serif',
  //     header: 'Basel, sans-serif',
  //   }
  // },
  rpcs: {
    ethereum: "https://rpc.ankr.com/eth",
    bsc: "https://rpc.ankr.com/bsc",
    polygon: "https://rpc.ankr.com/polygon"
  },
  showHamburgerMenu: false,
  tokensConfig: {
    "NESG": {
      "key": "NESG",
      "symbol": "NESG",
      "nativeChain": "bsc",
      "tokenId": {
        "chain": "bsc",
        "address": "0xfccf5becfcc0978a3a766476bb58d5f13565d999"
      },
      // "coinGeckoId": "basket",
      "icon": `${NESG}`,
      // "color": "#2894EE",
      "decimals": {
        "default": 18
      },
      "foreignAssets": {
        // "ethereum": {
        //   "address": "0xbC0899E527007f1B8Ced694508FCb7a2b9a46F53",
        //   "decimals": 5
        // },
        "polygon": {
          "address": "0xE64ceD357672e70fA5cE1fCAEc52c8F690528bcC",
          "decimals": 18
        },
      }
    }
  }
};

// const customized: WormholeConnectTheme = dark;
// customized.success = '#000000';
// customized.background.default = "#FD766B";
// customized.button.action = "#000000";
// customized.button.actionText = "#000000";


function App() {
  return (
    <>
      <>
        <ResponsiveAppBar />
      </>
      <WormholeConnect config={config} />
    </>
  );
}

export default App;
