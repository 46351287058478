import * as React from 'react';
import AppBar from '@mui/material/AppBar';
import { createTheme, ThemeProvider } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import Menu from '@mui/material/Menu';
import MenuIcon from '@mui/icons-material/Menu';
import Container from '@mui/material/Container';
import Avatar from '@mui/material/Avatar';
import NESG from "../Assets/NESG.png";
import Button from '@mui/material/Button';
import Tooltip from '@mui/material/Tooltip';
import MenuItem from '@mui/material/MenuItem';
import AdbIcon from '@mui/icons-material/Adb';

const pages = ['Swap']

function ResponsiveAppBar() {
    const [anchorElNav, setAnchorElNav] = React.useState(null);
    const [anchorElUser, setAnchorElUser] = React.useState(null);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };
    const handleOpenUserMenu = (event) => {
        setAnchorElUser(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleCloseUserMenu = () => {
        setAnchorElUser(null);
    };

    const theme = createTheme({
        typography: {
            fontFamily: 'Basel, sans-serif',
            textTransform: "capitalize"
        },

    });

    return (
        // <ThemeProvider theme={theme}>
        <AppBar position="absolute" style={{ background: "none", top: "0", width: "100%", boxShadow: "none" }}>
            <Container maxWidth="xl">
                <Toolbar disableGutters>
                    <Box sx={{ flexGrow: {xs: 0, md: 0}, display: "flex", height: { xs: "3.5vh", md: "7vh" },
                marginRight: {xs: "0", md: "2vw"}}}>
                        <img src={NESG} alt="NESGSwap" />
                    </Box>

                    {/* <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'none' } }}>
                            <IconButton
                                size="large"
                                aria-label="account of current user"
                                aria-controls="menu-appbar"
                                aria-haspopup="true"
                                onClick={handleOpenNavMenu}
                                color="inherit"
                            >
                                <MenuIcon />
                            </IconButton>
                            <Menu
                                id="menu-appbar"
                                anchorEl={anchorElNav}
                                anchorOrigin={{
                                    vertical: 'top',
                                    horizontal: 'right',
                                }}
                                keepMounted
                                transformOrigin={{
                                    vertical: 'bottom',
                                    horizontal: 'right',
                                }}
                                open={Boolean(anchorElNav)}
                                onClose={handleCloseNavMenu}
                                sx={{
                                    display: { xs: 'block', md: 'none' },
                                }}
                            >
                                {pages.map((page) => (
                                    <MenuItem key={page} onClick={handleCloseNavMenu}>
                                        <Typography textAlign="center">{page}</Typography>
                                    </MenuItem>
                                ))}
                            </Menu>
                        </Box> */}
                    {/* <AdbIcon sx={{ display: { xs: 'flex', md: 'none' }, mr: 1 }} />
                         */}
                    <Typography
                            variant="h6"
                            noWrap
                            component="a"
                            href="/"
                            sx={{
                                mr: {md: 2, xs: 0},
                                ml: {xs: 2},
                                // display: { xs: 'flex', md: 'none' },
                                flexGrow: {md: 0, xs: 1},
                                fontFamily: 'Basel, sans-serif',
                                fontWeight: 500,
                                color: 'inherit',
                                textDecoration: 'none',
                            }}
                        >
                            NESGbridge
                        </Typography>
                    <Box sx={{ flexGrow: 1, display: { xs: 'flex', md: 'flex' }, justifyContent: {xs: "flex-end", md: "flex-start"} }}>
                        {pages.map((page) => (
                            <Button
                                // style={{ textTransform: "capitalize" , fontFamily: 'Basel, sans-serif'}}
                                variant="h6"
                                noWrap
                                component="a"
                                href="https://swap.nesgswap.com/"
                                sx={{
                                    mr: 1,
                                    display: { xs: 'flex', md: 'flex' },
                                    fontSize: "15px",
                                    textTransform: "capitalize",
                                    fontFamily: 'Basel, sans-serif',
                                    fontWeight: 485,
                                    color: 'inherit',
                                    textDecoration: 'none',
                                }}
                                key={page}
                                onClick={handleCloseNavMenu}
                            // sx={{ my: 2, color: 'white', display: 'block' }}
                            >
                                {page}
                            </Button>
                        ))}
                    </Box>


                </Toolbar>
            </Container>
        </AppBar>
        // </ThemeProvider>
    );
}
export default ResponsiveAppBar;